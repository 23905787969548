import { camelizeKeys, decamelizeKeys } from "humps";
import { merge } from "lodash";
import axios from "axios";

import { GardenCrop, Crop, ActiveSubscriptionInfo, CropVariety } from "@shared/types";
import { UserColor } from "@shared/types/apiTypes";
import buildUrlWithParams from "helpers/buildUrlWithParams";

const defaultOptions = {
  credentials: "include"
} as const;

type QueryParams = Record<string, string>;

type FetchOptions = {
  queryParams?: QueryParams;
} & RequestInit;

const jsonFetch = <T>(
  url: string,
  { queryParams = {}, ...options }: FetchOptions = {}
) => {
  const urlWithParams = buildUrlWithParams(url, queryParams);

  return fetch(urlWithParams, { ...defaultOptions, ...options })
    .then((response) => response.json())
    .then(camelizeKeys) as Promise<T>;
};

const jsonMutate = <Params, Response>(url: string, params: Params, options = {}) =>
  fetch(
    url,
    merge({}, defaultOptions, options, {
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(decamelizeKeys(params))
    })
  )
    .then(async (response) => {
      if (!response.ok) {
        throw await response.json().catch(() => ({ status: response.status }));
      }

      return response.json().catch(() => ({ status: response.status }));
    })
    .then((json) => camelizeKeys<Response>(json));

export const fetchGardenCrops = ({
  startDate,
  endDate,
  yearMonth,
  monthsNumber
}: {
  startDate: string;
  endDate: string;
  yearMonth: string;
  monthsNumber: string;
}) =>
  jsonFetch("/api/garden_crops", {
    queryParams: { startDate, endDate, yearMonth, monthsNumber }
  });

export const completeTasks = ({
  completedAt,
  ids
}: {
  completedAt?: string;
  ids: number[];
}) => {
  jsonMutate("/api/tasks/complete_bulk", { completedAt, ids }, { method: "PATCH" });
};

export const fetchGardenCrop = (id: number | string) =>
  jsonFetch<GardenCrop>(`/api/garden_crops/${id}`);

/* @ts-expect-error auto-src: non-strict-conversion */
export const toggleGardenCrop = (id, visible) =>
  fetch(`/api/garden_crops/${id}/toggle?visible=${visible}`, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const createGardenCrop = (params) =>
  jsonMutate(`/api/garden_crops`, { gardenCrop: params }, { method: "POST" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const updateGardenCrop = (id, params) =>
  jsonMutate(`/api/garden_crops/${id}`, { gardenCrop: params }, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const copyGardenCrops = (id, params) =>
  jsonMutate(
    `/api/gardens/${id}/plantings_copies`,
    { plantingsCopies: params },
    { method: "POST" }
  );

/* @ts-expect-error auto-src: non-strict-conversion */
export const destroyGardenCrop = (id) =>
  fetch(`/api/garden_crops/${id}`, { method: "DELETE" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const addSuccessionPlanting = (id) =>
  fetch(`/api/plantings/${id}/add_succession`, { method: "PATCH" });

export const addSuccessionPlantingWarning = (id: number): Promise<{ warning?: string }> =>
  jsonMutate(`/api/plantings/${id}/add_succession_warning`, {}, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const duplicatePlanting = (id) =>
  fetch(`/api/plantings/${id}/duplicate`, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const destroyPlanting = (id) =>
  fetch(`/api/plantings/${id}`, { method: "DELETE" });

export const togglePlanting = (id: number | string) =>
  jsonMutate<Record<string, never>, { isVisible: boolean }>(
    `/api/plantings/${id}/toggle`,
    {},
    { method: "PATCH" }
  );

/* @ts-expect-error auto-src: non-strict-conversion */
export const linkPlantingCrop = (id) =>
  fetch(`/api/plantings/${id}/link`, { method: "PATCH" });

export const fetchCurrentGarden = () =>
  fetch("/api/gardens/current").then((result) => result.json());

export const fetchGarden = (id: string | number) =>
  fetch(`/api/gardens/${id}`).then((result) => result.json());

/* @ts-expect-error auto-src: non-strict-conversion */
export const updateGarden = (title) =>
  fetch(`/api/gardens?title=${title}`, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const destroyGarden = (id) =>
  jsonMutate(`/api/gardens/${id}`, {}, { method: "DELETE" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const switchGarden = (id) =>
  jsonMutate(`/api/gardens/${id}/switch`, {}, { method: "PATCH" });

export const toggleTasksGarden = () =>
  jsonMutate(`/api/gardens/toggle_tasks`, {}, { method: "PATCH" });

export const toggleTaskWarningsGarden = () =>
  jsonMutate(`/api/gardens/toggle_task_warnings`, {}, { method: "PATCH" });

export const fetchCrops = () => jsonFetch<Crop[]>("/api/crops");

export const destroyCrop = (id: number | string) =>
  jsonMutate(`/api/crops/${id}`, {}, { method: "DELETE" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const fetchCropInfo = (id) => jsonFetch(`/api/crops/${id}/info`);

export const fetchColors = () => jsonFetch<UserColor[]>("/api/colors");

export const fetchVarieties = (cropId: number) =>
  jsonFetch<CropVariety[]>(`/api/crop_varieties?crop_id=${cropId}`);

export const destroyCropVariety = (id: number | string) =>
  jsonMutate(`/api/crop_varieties/${id}`, {}, { method: "DELETE" });

export const fetchActiveSubscription = () =>
  jsonFetch<ActiveSubscriptionInfo>("/api/active_subscription");

export const downgradeToFreeSubscription = () =>
  fetch("/api/active_subscription/downgrade_to_free", { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const fetchNotes = (queryParams) => jsonFetch(`/api/notes`, { queryParams });

export const fetchNotePlantings = () => jsonFetch(`/api/notes/plantings`);

/* @ts-expect-error auto-src: non-strict-conversion */
export const updateNote = (id, params) =>
  jsonMutate(`/api/notes/${id}`, { note: params }, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const destroyNote = (id) =>
  jsonMutate(`/api/notes/${id}`, {}, { method: "DELETE" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const createNote = (params) =>
  jsonMutate("/api/notes", { note: params }, { method: "POST" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const upsertNoteImages = ({ noteId, formData, onUploadProgress }) =>
  axios
    .put(`/api/notes/${noteId}/images/upsert`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent?.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (onUploadProgress) {
            onUploadProgress(percentCompleted);
          }
        }
      }
    })
    .then((response) => {
      return response.data;
    });

/* @ts-expect-error auto-src: non-strict-conversion */
export const upsertNoteTags = ({ noteId, formData }) =>
  fetch(`/api/notes/${noteId}/tags/upsert`, { body: formData, method: "PUT" }).then(
    (response) => response.json()
  );

/* @ts-expect-error auto-src: non-strict-conversion */
export const upsertNoteGardenCrops = ({ noteId, formData }) =>
  fetch(`/api/notes/${noteId}/garden_crops/upsert`, {
    body: formData,
    method: "PUT"
  }).then((response) => response.json());

/* @ts-expect-error auto-src: non-strict-conversion */
export const fetchTasks = (date, view) =>
  jsonFetch(`/api/tasks?date=${date}&view=${view}`);

/* @ts-expect-error auto-src: non-strict-conversion */
export const fetchTask = (id) => jsonFetch(`/api/tasks/${id}`);

/* @ts-expect-error auto-src: non-strict-conversion */
export const completeTask = (id, params = {}) =>
  jsonMutate(`/api/tasks/${id}/complete`, params, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const todoTask = (id) =>
  jsonMutate(`/api/tasks/${id}/todo`, {}, { method: "PATCH" });

/* @ts-expect-error auto-src: non-strict-conversion */
export const moveTask = (id, { startDelta, endDelta, startDate, endDate, isMove }) =>
  jsonMutate(
    `/api/tasks/${id}/move`,
    { startDelta, endDelta, startDate, endDate, isMove },
    { method: "PATCH" }
  );

export const moveWarningTask = (
  /* @ts-expect-error auto-src: non-strict-conversion */
  id,
  /* @ts-expect-error auto-src: non-strict-conversion */
  { startDelta, endDelta, startDate, endDate, isMove }
) =>
  jsonMutate(
    `/api/tasks/${id}/move_warning`,
    { startDelta, endDelta, startDate, endDate, isMove },
    { method: "PATCH" }
  );

type FirstPromoterProfileParams = {
  firstPromoterProfile: {
    campaign: string;
  };
};

type FirstPromoterProfileResponse = {
  firstPromoterAuthToken: string | null;
};

/* @ts-expect-error auto-src: non-strict-conversion */
export const createUsersFirstPromoterProfile = ({ campaign }) =>
  jsonMutate<FirstPromoterProfileParams, FirstPromoterProfileResponse>(
    "/api/users/first_promoter_profile",
    { firstPromoterProfile: { campaign } },
    { method: "POST" }
  );
