import { useMemo } from "react";

import { TaskTypes, TaskType, TaskFilter } from "types";
import usePersistedState from "hooks/usePersistedState";
import { parseTaskFilter, serializeTaskFilter } from "helpers/taskFilterSerialization";

type Args = {
  localStorageKey: string;
};

type Return = {
  taskFilter: TaskFilter;
  setTaskVisibility: (taskType: TaskType, visible: boolean) => void;
};

const useTaskFilter = ({ localStorageKey }: Args): Return => {
  const [persistedValue, setPersistedValue] = usePersistedState<string | null>({
    key: localStorageKey,
    defaultValue: null
  });

  const taskFilter = useMemo(() => parseTaskFilter(persistedValue), [persistedValue]);

  const setTaskVisibility = (taskType: TaskType, visible: boolean) => {
    const newTaskFilter: TaskFilter = { ...taskFilter, [taskType]: visible };

    if (taskType === TaskTypes.BedPreparation) {
      newTaskFilter[TaskTypes.StaleSeedBed] = visible;
    }

    setPersistedValue(serializeTaskFilter(newTaskFilter));
  };

  return {
    taskFilter,
    setTaskVisibility
  };
};

export default useTaskFilter;
