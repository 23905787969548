export { TaskTypes } from "@shared/types";
export type { CropTask, TaskType } from "@shared/types";
export type { FilterOptionsUpdateNotification } from "./FilterOptionsUpdateNotification";
export type { ResourceGroupingMapping } from "./ResourceGroupingMapping";
export type { TaskFilter } from "./TaskFilter";
export type { WindowWithState } from "./WindowWithState";

declare global {
  interface Window { SEEDTIME_FEATURES: string[]; }
}
